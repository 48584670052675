<template>
    <div>
        <bread-crumb :pageTitle="'Checkout'"></bread-crumb>
        <div class="container pb-5 mb-sm-4 mt-n2 mt-md-n3">
            <!-- Steps (wizard)-->
            <checkout-steps :stepNumber="5"></checkout-steps>
            <div class="row pt-4 mt-2">
                <!-- Content-->
                <div class="col-xl-9 col-md-8">
                    <h2 class="h6 px-4 py-3 bg-secondary mb-4">Review your order</h2>
                    <!-- Item-->
                    <cart-item v-for="cartItem in cartItems" :key="cartItem.productId" :id="cartItem.productId" :qty="cartItem.qty" :updatable="false"></cart-item>
                    <!-- Client details-->
                    <div class="bg-secondary px-4 pt-4 pb-2">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4 class="h6">Shipping to:</h4>
                                <ul class="list-unstyled">
                                    <li><span class="text-muted">Client:&nbsp;</span>{{ fullName }}</li>
                                    <li><span class="text-muted">Address:&nbsp;</span>{{ shipAddress }}</li>
                                    <li><span class="text-muted">Phone:&nbsp;</span>{{ shippingAddress.phone }}</li>
                                </ul>
                            </div>
                            <div class="col-sm-6">
                                <h4 class="h6">Payment method:</h4>
                                <ul class="list-unstyled">
                                    <li><span class="text-muted">Credit Card:&nbsp;</span>**** **** **** {{ last4 }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Navigation-->
                    <div class="d-flex pt-4">
                        <div class="w-50 pr-3"><router-link :to="{ name: 'checkout-payment'}" class="btn btn-outline-secondary btn-block"><i class="mr-1" data-feather="chevron-left"></i>Back</router-link></div>
                        <div class="w-50 pl-2"><a class="btn btn-primary btn-block" href="#" v-bind:class="{ disabled: isProcessing }" @click.prevent="processOrder()"><span class="d-none d-sm-inline">Complete order</span><span class="d-inline d-sm-none">Complete</span><i class="ml-1" data-feather="chevron-right"></i></a></div>
                    </div>
                </div>
                <!-- Sidebar-->
                <checkout-order-summary v-bind:show-promo-code-form="false"></checkout-order-summary>
            </div>
        </div>
        <BlockUI v-if="isProcessing" :message="processMessage">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </BlockUI>
    </div>
</template>

<script>
    import router from './../router';
    import BreadCrumb from "../components/BreadCrumb";
    import CheckoutSteps from "../components/CheckoutSteps";
    import CartItem from "../components/CartItem";
    import CheckoutOrderSummary from "../components/CheckoutOrderSummary";

    export default {
        name: "CheckoutReviewPage",
        components: {BreadCrumb, CheckoutSteps, CartItem, CheckoutOrderSummary},
        data() {
            return {
                shippingAddress: Object.assign({}, this.$store.state.shippingAddress),
                billingAddress: Object.assign({}, this.$store.state.billingAddress),
                requiresShipping: this.$store.getters.getRequiresShipping,
                last4: this.$store.state.last4,
                stripeSource: this.$store.state.stripeSource,
                isProcessing: false,
                processMessage: 'Processing your orders. Please wait...'
            }
        },
        computed: {
            cartItems() {
                return this.$store.state.cart;
            },
            shipAddress() {
                var shippingAddressLine = (this.shippingAddress.address2) ? this.shippingAddress.address1 + ' ' + this.shippingAddress.address2 : this.shippingAddress.address1;
                return shippingAddressLine + ' ' + this.shippingAddress.city + ' ' + this.shippingAddress.state + ' ' + this.shippingAddress.postalcode + ' ' + this.shippingAddress.country;
            },
            fullName() {
                return (this.shippingAddress.lastName) ? this.shippingAddress.firstName + ' ' + this.shippingAddress.lastName : this.shippingAddress.firstName;
            }
        },
        methods: {
            processOrder: async function () {
                this.isProcessing = true;
                const accessToken = await this.$auth.getAccessToken();
                const url = encodeURI(process.env.MIX_IDENTIVEW_API_BASEURL + 'users/' + this.$auth.profile.sub + '/orders/');

                var payload = {};
                var order = {};
                order.shipAddressFirstName = this.shippingAddress.firstName;
                order.shipAddressLastName = this.shippingAddress.lastName;
                order.shipAddressEmailAddress = this.shippingAddress.emailAddress;
                order.shipAddressPhone = this.shippingAddress.phone;
                order.shipAddressAddress1 = this.shippingAddress.address1;
                order.shipAddressAddress2 = this.shippingAddress.address2;
                order.shipAddressCity = this.shippingAddress.city;
                order.shipAddressState = this.shippingAddress.state;
                order.shipAddressPostalCode = this.shippingAddress.postalcode;
                order.shipAddressCountry = this.shippingAddress.country;
                order.billAddressFirstName = this.billingAddress.firstName;
                order.billAddressLastName = this.billingAddress.lastName;
                order.billAddressEmailAddress = this.billingAddress.emailAddress;
                order.billAddressPhone = this.billingAddress.phone;
                order.billAddressAddress1 = this.billingAddress.address1;
                order.billAddressAddress2 = this.billingAddress.address2;
                order.billAddressCity = this.billingAddress.city;
                order.billAddressState = this.billingAddress.state;
                order.billAddressPostalCode = this.billingAddress.postalcode;
                order.billAddressCountry = this.billingAddress.country;
                order.shippingAmount = this.$store.state.shippingAmount;
                order.discountCodeApplied = this.$store.state.discountCodeApplied;
                payload.order = order;
                payload.stripeSource = this.stripeSource;
                payload.leadSource = this.$store.getters.getIsFirstOrder ? this.$store.getters.getLeadSource : "";

                var orderItems = [];
                var cartItems = this.cartItems;
                cartItems.forEach(function (cartItem) {
                    var orderItem = {};
                    orderItem.productID = cartItem.productId;
                    orderItem.quantity = cartItem.qty;
                    orderItems.push(orderItem);
                });
                payload.orderItems = orderItems;

                // Need to add access token
                // https://auth0.com/docs/quickstart/spa/vuejs/03-calling-an-api#call-the-api-using-an-access-token
                var that = this;
                return axios.post(url, payload,
                    {
                        crossDomain: true,
                        headers: {
                            Authorization: 'Bearer ' + accessToken
                        }
                    })
                    .then(response => {
                        that.isProcessing = false;
                        this.$gtag.purchase({
                            "transaction_id": response.data.orderNumber,
                            "affiliation": "DiamondEye Consumer",
                            "value": this.$store.getters.getTotalOrderAmount,
                            "currency": "USD",
                            "shipping": this.$store.state.shippingAmount
                        })
                        router.push({
                            name: 'checkout-complete',
                            params: {requiresShipping: this.requiresShipping, orderNumber: response.data.orderNumber}
                        });
                    })
                    .catch(error => {
                        this.isProcessing = false;
                        if (error.response) {
                            console.log(error.response);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log(error.message);
                        }
                    });
            },
        }
    }
</script>

<style scoped>
</style>
