import { render, staticRenderFns } from "./BuyNowButton.vue?vue&type=template&id=2e6dfca7&scoped=true&"
import script from "./BuyNowButton.vue?vue&type=script&lang=js&"
export * from "./BuyNowButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6dfca7",
  null
  
)

export default component.exports